  <template>
  <div class="s-flat-box">
    <div class="s-plr-16">
      <h2>{{ $t("forgotPassword.title") }}</h2>
      <p>{{ $t("forgotPassword.subTitle") }}</p>
      <div>
        <Input
          v-model="email"
          class="s-mt-50"
          label="Email"
          placeholder="johndoe@email.com"
          :error="error.email"
        ></Input>
      </div>
      <div class="s-fullWidth s-mt-30">
        <Button @click.prevent="onFinish()" class="s-fullWidth" :isLoading="isLoading">{{
          $t("forgotPassword.updatePassword")
        }}</Button>
      </div>
    </div>
    <hr class="s-line-primary s-mtb-16" />
    <div class="s-plr-16">
      <div class="s-fullWidth s-mt-30">
        <router-link
          :to="
            $translate({
              name: 'authentication',
              params: { authType: 'create-account' },
            })
          "
        >
          <Button class="s-fullWidth" color="secondary">{{
            $t("forgotPassword.createAccount")
          }}</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import form from "@/mixins/form";
import { mapActions } from "vuex";

export default {
  mixins: [form],
  data: () => ({
    email: "",
    error: {
      email: false,
    },
    isLoading: false,
  }),

  methods: {
    ...mapActions({
      sendEmail: "auth/forgotPassword",
    }),
    async validation() {
      let errorCount = 0;
      if (this.email.length == 0) {
        this.error.email = true;
        errorCount++;
      }
      return errorCount;
    },
    async onFinish() {
      this.isLoading= true
      if ((await this.validation()) == 0) {
        await this.sendEmail({ email: this.email });
     
        if (this.$store.getters["auth/getStatus"] == 0) {
          this.showSnackbar({
            type: "error",
            text: "Email not sent!",
          });
        } else {
          this.showSnackbar({
            type: "success",
            text: "Email sent!",
          });
        }
      }
          this.isLoading= false
    },
  },
};
</script>